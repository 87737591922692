/* eslint-disable */
import React from "react"
import Title from "../../Title"
import styles from "../../../css/locked.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
const Locked = () => {

  return (
    <section className={styles.locked}>
      <span className={styles.modalHeader}><Title title="Activation" subtitle="Locked" /></span>
      <p className={styles.instructionsModal}>Your email account has been locked because you entered too many failed codes. Please try again in 20 minutes. You can also sign up to recieve a valid invite code from Rivet by entering your email below.</p>
      <div className={styles.center}>
        <form className={styles.form}>
          <div className={styles.row2}>
            <span className={styles.email}><input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              className={styles.formControl}
            /></span>
          </div>
          <div className={styles.arrowButton}>
            <input
              type="submit"
              value="signup"
              className={styles.submit}
              action="/signup/"
            />
          </div>
        </form>
      </div>
    </section>
  )
}

export default Locked
